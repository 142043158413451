// COLORS
// primary
$blue: #0057FF;
$lightBlue: #E0E8FD;
$blueGrey: #637888;
$green: #AAE5BF;
$green2: #1DB954;

$inAppBlue: #2051FE;
$inAppBlueBackground: #E3E9FF;
$inAppRed: #EC4A5E;
$inAppRedBackground: #FDE8EB;

// neutrals
$white: #fff;
$black: #000;
$darkGrey: #14171A;
$grey: #5A5D60;
$lightGrey: #DCDFE2;
$lighterGrey: #EDEEF0;
$almostWhite: #F4F7FA;

// reds
$error: red;

// SIZES
$padding: 1rem;

$contentLineHeight: 1.375;

// FONTS
$baseFont: neue-haas-unica, sans-serif;
$baseFontColor: $darkGrey;
$baseFontSize: .875rem;
$baseFontSizeMobile: 1rem;
$baseFontWeight: 400;
$baseFontWeightSemiBold: 500;
$baseFontWeightBold: 600;
$headingFont: $baseFont;
$headingColor: $darkGrey;

// FORMS
$formHeight: 3rem;
$formHeightSmall: 2.25rem;
$formBorderColor: $lightGrey;
$formBorderWidth: 0.0625rem;
$formActiveBorderColor: $blue;
$formPlaceholderColor: $blueGrey;

// BREAKPOINTS
$largeMobileBreakpoint: 29.5em; // <472px
$smallTabletBreakpoint: 36.5em; // <592px
$tabletBreakpoint: 47.5em; // <768px
$desktopBreakpoint: 63em; // <1024px
$widescreenBreakpoint: 80em; // <1280px
$superWideBreakpoint: 112.5em; // <1808px

// SITE SPECIFIC
$linkColor: $blue;
$linkColorHover: darken($blue, 8%);
$linkColorDestructive: $error;

$labelColor: $baseFontColor;
$labelFont: $baseFont;
$labelWeight: bold;
$labelRequiredColor: $error;
$labelTextTransform: none;

$borderRadius: .5rem;
$cardShadow: 0 .125rem .375rem 0 rgba($black, .08);
$headerHeight: 3.5rem;
$menuWidth: (236rem/16);
$formShadow: 0 .25rem .625rem 0 rgba($black, .2);


