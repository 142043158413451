.chart-wrapper {

  p {
    font-size: 9pt;
    padding-left: 1rem;
  }

  display: inline-block;
  margin-left: 2rem;
  //margin-right: 2rem;
  border-style: solid;
  border-radius: 3px;
  //border-width: 1px;
  border-color: lightgrey;
}
