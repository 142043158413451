.setups-wrapper {

  display: flex;
  //background: blue;
  flex-direction: column;

  width: 100%;


  .title {
    display: flex;
    justify-content: center;
    //width: 100%;
    padding-top: 1rem;
    padding-bottom: 2rem;

  }
}

.setup-quick-entry {
  //background: #637888;
  display: flex;
  p {
    margin-left: 5px;
    margin-right: 5px;
  }
  input {
    margin-right: 5px;
  }
}

.setup-quick-entry-wrapper {
  display: flex;
  justify-content: center;
}

.setup-list {
  //background: lightgray;
}
