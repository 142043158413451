.backtest-listing-wrapper {

  display: flex;
  //background: blue;
  flex-direction: column;

  width: 100%;


  .title {
    display: flex;
    justify-content: center;
    //width: 100%;
    padding-top: 1rem;
    padding-bottom: 2rem;

  }

  .h2 {
  }
}
.c-activity-stream-list {
  //overflow-x: auto;
  //background: green;
  padding-left: 1rem;
  padding-right: 1rem;
}
