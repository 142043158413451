.position-wrapper {
  display: flex;
  flex-direction: column;

  width: 100%;


  .title {
    display: flex;
    justify-content: center;
    //width: 100%;
    padding-top: 1rem;
    padding-bottom: 2rem;

  }
}
