@import "src/scss/variables";

.c-button {
    display: inline-block;
    width: auto;
    height: $formHeight;
    line-height: $formHeight - .125rem;
    padding: 0 1.5rem;

    background: $blue;
    border: $formBorderWidth solid $blue;
    border-radius: 1.5rem;

    color: $white;
    font-family: $baseFont;
    font-size: $baseFontSize;
    font-weight: $baseFontWeightBold;
    text-align: center;
    text-decoration: none;

    transition: all .2s;
    perspective: 1000px;
    transform-origin: center bottom 0;
    transform: matrix(1, 0, 0, 1, 0, 0);

    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
        background-color: darken($blue, 5%);
        border-color: darken($blue, 5%);

        text-decoration: none;
    }

    &:active {
        transform: matrix(0.97, 0, 0, 0.97, 0, 0);
    }

    &:focus {
        outline: none;
    }

    &:disabled,
    &.a-button--disabled {
        pointer-events: none;
        opacity: .6;
    }

    &.c-link {
        &:hover {
            text-decoration: none;
        }
    }
}

.c-button__activity-indicator {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background: $darkGrey;
    border-radius: 1.25rem;

    .c-activity-indicator {
        width: 1rem;
        height: 1rem;
    }

    .a-button--no-styles & {
        background: $white;
    }
}

.a-button--secondary {
    background: transparent;

    color: $blue;

    &:hover,
    &:focus,
    &:active {
        background-color: darken($white, 5%);

        text-decoration: none;
    }
}

.a-button--light {
    background: transparent;
    border-color: $lightGrey;

    color: $grey;

    &:hover,
    &:focus,
    &:active {
        background-color: darken($white, 5%);

        text-decoration: none;
    }

    &:disabled {
        background: transparent;
    }
}

.a-button--no-styles,
.a-button--link,
.a-button--link-secondary {
    transition: none;
    height: auto;
    min-width: auto;
    padding: 0;
    line-height: $contentLineHeight;

    background: transparent;
    border: 0;
    box-shadow: none;

    color: $baseFontColor;
    font-weight: $baseFontWeight;
    text-transform: none;

    &.destructive {
        color: $linkColorDestructive;

        &:hover {
            color: darken($linkColorDestructive, 8%);
        }
    }

    &:hover,
    &:focus {
        background: transparent;
        border: 0;

        color: darken($baseFontColor, 5%);
    }

    &:active {
        background: transparent;
        border: 0;

        color: darken($baseFontColor, 10%);
        transform: matrix(1, 0, 0, 1, 0, 0);
    }

    &:disabled {
        background: transparent;
    }
}

.a-button--link,
.a-button--link-secondary {
    color: $linkColor;

    &:hover,
    &:focus {
        background: transparent;
        border: 0;

        color: darken($linkColor, 8%);
    }

    &:active {
        background: transparent;
        border: 0;

        color: darken($linkColor, 15%);
        transform: matrix(1, 0, 0, 1, 0, 0);
    }
}

.a-button--link-secondary {
    color: $blueGrey;
    font-weight: $baseFontWeightBold;
    font-size: 0.75rem;
}

.a-button--small {
    height: $formHeightSmall;
    line-height: $formHeightSmall - .125rem;
    padding: 0 1rem;
}


.a-button--add {
    background: white;
    color: $baseFontColor;
    border-color: $formBorderColor;
    position: relative;
    padding-left: $formHeight + 0.5rem;

    &:hover,
    &:focus {
        border-color: $formActiveBorderColor;
        background: $lightGrey;
    }

    .c-button__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: $formBorderWidth;
        top: $formBorderWidth;
        height: $formHeight - ($formBorderWidth * 4);
        width: $formHeight - ($formBorderWidth * 4);
        background: $darkGrey;
        border-radius: 50%;
        .c-icon {
            height: 0.66rem;
            width: 0.66rem;
            path {
                fill: $white;
            }
        }
    }

    &.a-button--small {
        padding-left: $formHeightSmall + 0.5rem;
        .c-button__icon {
            height: $formHeightSmall - ($formBorderWidth * 4);
            width: $formHeightSmall - ($formBorderWidth * 4);
        }
    }
}
