.backtest-wrapper {
  //display: flex;
  //flex-direction: column;

  //width: 100%;
  margin-right: 1rem;

  margin-left: 1rem;


  .title {
    display: flex;
    justify-content: center;
    //width: 100%;
    padding-top: 1rem;
    padding-bottom: 2rem;

  }
}

.source-code {
  margin: 1rem;
  padding: 1rem;
  background: #CCCCCC;
  border-radius: 3px;
}

