.home-wrapper {

  display: flex;
  //background: blue;
  flex-direction: column;

  width: 100%;


  .title {
    display: flex;
    justify-content: center;
    //width: 100%;
    padding-top: 1rem;
    padding-bottom: 2rem;

  }

  .h2 {
  }

  .home-items {

    display: flex;
    justify-content: center;

    width: 100%
  }

  .home-item {
    border-width: 2px;
    border-color: black;
    border-style: solid;

    display: flex;
    justify-content: center;

    width: 30%;
    padding: 30px;
    margin: 20px;

    &:hover {
      background:gray;
      color: white;
      cursor: pointer;
    }
  }
}
