@import "src/scss/variables";

.c-table {
    width: 100%;
    border-collapse: collapse;

    thead {
        padding-bottom: .75rem;
    }

    th {
        margin: 0;
        padding: 0;
        padding-bottom: .625rem;
        padding-right: .625rem;

        border: 0;
        border-bottom: .125rem solid $lightGrey;
        border-spacing: 0;

        font-size: .75rem;
        text-align: left;
        text-transform: uppercase;
    }

    td {
        padding: .75rem;
        padding-left: 1.5rem;

        border: 0;
        border-spacing: 0;
        border-left: .0625rem solid $lighterGrey;

        font-size: .875rem;

        &:first-child {
            padding: .75rem;

            border-left: 0;

            font-weight: bold;
        }
    }

    tbody {
        &:before {
            content: '';

            display: block;
            height: .75rem;
        }

        tr {
            &:nth-child(even) {
                //background-color: rgba(247,210,71,.1);
            }
        }
    }
}
